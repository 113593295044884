import React, {useContext, useEffect} from 'react';
import {fetchGet, getWispUrl, wisp_urls} from './util/fetch.js';
import {WispContext} from './WispProvider';
import {getDateStr} from './util/base.js';
import Decision from './components/Decision.js';
import { Referral } from './components/Referral';
import RemoveSitePopup from './popups/RemoveSitePopup.js';
import {wispHistory} from './components/WispHistory.js';
import {createSite, goToSite} from './util/site.js';
import {userHasSetupStripe} from './util/user.js';
import { WispMenu } from './components/WispMenu';

export const DashboardView = (props) => {

	var forceUpdateCount = 0;

    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;
	const [forceUpdate, setForceUpdate] = React.useState(0);
	const [removeSite, setRemoveSite] = React.useState(null);
    const [showReferral, setShowReferral] = React.useState(false);
	const [showRemoveSitePopup, setShowRemoveSitePopup] = React.useState(false);

    useEffect(() => {
        console.log('userSites = ' + wispData.userSitesAuth);
        var url = getWispUrl(wisp_urls.user_stripeInfo_get, wispData.publicState.wispUser.id);
		fetchGet(url, getStripeInfoResult, wispData);

		if (props.sites) {
			props.sites.forEach((site) => {
				var url = getWispUrl(wisp_urls.site_thumbnail_get, site.id);
				fetchGet(url, getThumbnailResult, wispData, false, site.id, {}, 'html');
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sites]);

    function getStripeInfoResult(success, data) {
        if (success) {
            wispData.setUserStripeInfo(data);
        }
    }

	function preCreate() {
		wispData.setShowAddPopup(true);
	}

	function newBookDiv() {
		return (
			<div className='wisp_in wisp_dash_item' onClick={preCreate}>
				<div style={{ height: '155px', lineHeight:'133px',border:'3px solid #CCC',width:'115px',textAlign:'center',fontSize:'50px',color:'#CCC'}}>
					+
				</div>
				<div style={{ fontSize: '11px', textAlign: 'center', color: '#CCC', marginTop: '2px' }}>
					Add New
				</div>
			</div>
		);
	}

    function getBookExpireText(site) {
        var today = new Date();  
        var time_now = today.getTime();
        var time = site.user_access.end_date;
		var ttime = new Date(time);
        if(ttime < time_now){
            return (
                <span style={{color:'#880000'}}>
                    Expired: {getDateStr(time,false,false)}
                </span>
            );
        }
        return (
            <React.Fragment>
                Expires: {getDateStr(time,false,false)}
            </React.Fragment>
        );    
	}

	function buyFN() {
		wispData.setShowAddPopup(false);
		console.log('dashboardview');
		wispData.showSubscribePopup(true);
	}

	function createFN() {
		wispData.setShowAddPopup(false);
//		wispData.setNewSite(site);
		var user = wispData.publicState.wispUser;
		createSite(wispData,  user.wisp_id, goToSite, 'book');
	}

	function closeAddPopup() {
		wispData.setShowAddPopup(false);
	}
    
	/* function getThumbnail(site_id) {
		props.thumbs[site_id] = 'fetching';
		setTimeout(function () { wispData.setSiteThumbnails(props.thumbs) }, 50); // react doesn't like setting state in render so use timeout
		var url = getWispUrl(wisp_urls.site_thumbnail_get, site_id);
		fetchGet(url, getThumbnailResult, wispData, false, site_id, {}, 'html');
	} */

	function getThumbnailResult(success, data, site_id) {
		if (success) {
			var thumbs = wispData.siteThumbnails;
			thumbs[site_id] = data;
            wispData.setSiteThumbnails(thumbs);
            forceUpdateCount = forceUpdateCount + 1;
			setForceUpdate(forceUpdateCount);   // can't rely on the forceUpdate value itself because this thing can get called twice before forceUpdate updates
		}
	}

	function handleMenuItem(key, id) {
		var selectedSite;
		if (props.sites) {
			props.sites.forEach((site) => {
				if (site.id === id) {
					selectedSite = site;
				}
			});
		}
		if (key === 'settings') {
			if (props.sites) {
				props.sites.forEach((site) => {
					if (site.id === id) {
						goToSite(selectedSite.url);
					}
				});
			}
		} else if (key === 'delete') {
			setShowRemoveSitePopup(true);
			setRemoveSite(selectedSite);
		}
	}

	function closeRemoveSitePopup(deleted) {
		if (deleted) {
			props.updateSitesFN();
		}
		setShowRemoveSitePopup(false);
	}

	function onPromo() {
        setShowReferral(true);
    }

    var user = wispData.publicState.wispUser;
    var userStripeInfo = wispData.publicState.userStripeInfo;
    var books;
	var thumbs = wispData.siteThumbnails;
    if (props.sites && props.sites.length > 0){
		books = props.sites.map(site => {

            var siteMenu;
            var expireText;
            if(site.user_access.role === 'admin'){
                var bk = '0,200,0';
                var tip = site.name + " is for sale";
				var items = [
					{ key: 'settings', value: 'Settings' },
					{ key: 'delete', value: 'Delete' },
				]
				var menuStyle = { right: '-18px', top: '34px' };
				var iconStyle = { fontSize: '15px', padding: '2px 6px', cursor: 'pointer' };
				siteMenu = (
					<div className='dollar_wisp' title={tip} style={{ background: 'rgb(' + bk + ')' }}>
						<div style={{position: 'relative'}}>
							<WispMenu id={site.id} items={items} itemFN={handleMenuItem} iconStyle={iconStyle} menuStyle={menuStyle} />
						</div>
                    </div>
                );

            } else if (site.access === 'subscription') {
                expireText = (
                    <div style={{fontSize:'10px',textAlign:'center',marginTop:'8px'}}>
                        {getBookExpireText(site)}
                    </div>
                );
            }

            // got the thumbnail? If not, load it
            var thumbnail;

			if (thumbs[site.id] !== null) {
				thumbnail = (
					<div dangerouslySetInnerHTML={{ __html: thumbs[site.id] }} />
				);
            }

            return (
                <div key={site.url} className='wisp_in wisp_dash_item'>
					{siteMenu}
                    <div id={site.url} onClick={() => goToSite(site.url, '')} style={{height:'155px',overflowY:'hidden',background:'white',boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}} >
                        <div id={site.url + '_pages'} style={{position:'relative',minWidth:'115px'}}>
                            {thumbnail}
                        </div>
                    </div>
                    <div style={{fontSize:'10px',textAlign:'center',marginTop:'8px'}}>{site.name}</div>
                    {expireText}
                </div>
            );
        });
	}
	// get rid of forceUpdate warning
	if (forceUpdate) {
		var i; i = 0;
	}
	var addPopup;
	if (wispData.showAddPopup) {
		addPopup = (
			<Decision
				mes={"Do you want to buy a WispBook or create one?"}
				secondaryClass='primaryButton'
				c1="Buy"
				c2="Create"
				fn1={buyFN}
				fn2={createFN}
				p1=""
				p2=""
				y="40"
				closeFN={closeAddPopup}
			/>
		);
	}

	var removeSitePopup;
	if (showRemoveSitePopup) {
		removeSitePopup = (
			<RemoveSitePopup closeFN={closeRemoveSitePopup} site={removeSite} type='Book' />
		);
	}

	var promo = (
        <div className='wispPromo' onClick={onPromo}>Tell professors about WispBook and earn up to $1000 per referral! <span className='wispLink'>Learn more</span></div>
    )
    var referral;
    if (showReferral) {
        referral = (
            <Referral user={user}/>
        )
    }

    return (
        <div style={{marginTop: '80px'}}>
			<div id="webt_ui_dashboard" style={{ width: '100%', height: '100%', background: '#FFF', boxSizing: 'border-box', paddingLeft: '30px', paddingRight: '30px' }} >
				{addPopup}
				{removeSitePopup}
                <p id="dashboard_intro1" style={{marginLeft:'20px',paddingTop:'30px'}}><i>Hello</i></p>
                <p id="dashboard_intro2" className='panel_title' style={{marginLeft:'20px'}}>{user ? user.first_name : ''}</p>
                <div id="dashboard_books" style={{margin:'20px 0px 30px',width:'100%'}}>
                    <div className='dashboard_title_div'>
                        <div id="dashboard_books_title" className='wisp_app'><span style={{marginLeft:'25px'}}>Your WispBooks</span></div>
                    </div>
					<div id="dashboard_books_list" className='wisp_app_list'>{books}{newBookDiv()}</div>
                </div>
            </div>
			{promo}
            {referral}
        </div>
    );
}

/*

                    <div id="dashboard_data" style={{margin:'50px 0px 30px',width:'100%'}}>
                        <div className='dashboard_title_div'>
                            <div id="dashboard_data_title" className='wisp_in wisp_data_div active'><span style={{marginLeft:'25px'}}>Collected Data</span></div>
                        </div>
                        <div id="dashboard_formData"></div>
                    </div>
                    <div id="dashboard_stats" style={{margin:'50px 0px 30px',width:'100%',display:'none'}}>
                        <div className='dashboard_title_div'>
                            <div id="dashboard_stats_title" className='wisp_in wisp_data_div active'><span style={{marginLeft:'25px'}}>Stats</span></div>
                        </div>
                        <div id="dashboard_statsDiv"></div>
                    </div>
                    <div id="dashboard_info" style={{margin:'50px 0px 20px'}}>
                        <div id="dashboard_infoMessage" style={{display:'inline-table',paddingRight:'20px',boxSizing:'border-box',width:'33%'}}>
                            <div id="dashboard_message_title" className='wisp_app'><span style={{marginLeft:'25px'}}>Messages</span></div>
                            <div id="dashboard_message_div" className='ts_logEl'></div>
                        </div><div id="dashboard_infoTask" style={{display:'inline-table',paddingRight:'20px',boxSizing:'border-box',width:'33%'}}>
                            <div id="dashboard_task_title" className='wisp_app'><span style={{marginLeft:'25px'}}>Assignments</span></div>
                            <div id="dashboard_task_div" className='ts_tasksEl'></div>
                        </div><div id="dashboard_infoEvents" style={{display:'inline-table',boxSizing:'border-box',width:'33%'}}>
                            <div id="dashboard_events_title" className='wisp_app'><span style={{marginLeft:'25px'}}>Events</span></div>
                            <div id="dashboard_event_div" className='ts_calEl'></div>
                        </div>
                    </div>
*/