import React, {useEffect, useState} from 'react';
import $ from 'jquery';

export const WispInput = (props) => {
    const [value, setValue] = useState('');        // Tracks input value
    const [focused, setFocused] = useState(false); // Tracks if input is focused

    useEffect(() => {
        const initValue = props.initValue || '';
        $("#" + props.id).val(props.initValue);
        setValue(initValue);
        if (props.clearInput) {
          setValue(''); // Clear the input value
          props.setClearInputFlag(false); // Reset flag after clearing
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.clearInput, props.initValue]);

	function handleKeyUp(e) {
		// console.log('handlePasswordKeyUp = ' + e.target.value);
		if (e.keyCode === 13 && props.enterFN) {
			props.enterFN();
		}
	}
    
  function handleChange(e) {
    var newValue = e.target.value;
    if (props.upperCase) {
      newValue = newValue.toUpperCase()
    }
    setValue(newValue);

    if (props.onChange) {
      props.onChange(newValue); // This will update the parent state
    }
  }

  function handleFocus() {
    setFocused(true);
  }

  function handleBlur() {
    setFocused(false);
  }

  function clearValue() {
    setValue('')
  }

    // id
    // hint
    // hint2
    // ti
    // hint2Style
    // inputStyle
    var divId = props.id + "_div";
    var ahintId = props.id + '_ahint';
    var classes = 'wt_inputdiv';
    if (props.classes) {
        classes += ' ' + props.classes;
    }
    return (
        <div id={divId} className={classes}>
            <div className={`${focused || value ? 'input_hint_shrunk' : 'input_hint'}`}>
                {props.hint}
            </div>
			      <input 
                tabIndex={props.ti}
                style={props.inputStyle}
                className='app_input_common app_input'
                id={props.id}
                onKeyDown={e => handleKeyUp(e)}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={value}
            />
        </div>
    );
}

//     return "<div class='wisp_but webt_ui_color_1 " + bcls + "' style='display:" + d + ";padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ";' onclick='javascript:" + js + "'>" + mes + "</div>";
