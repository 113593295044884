/* global ts_h_email */

import React, {useContext, useEffect} from 'react';
import { showPubMessage2 } from '../util/base.js'; 
import Decision from '../components/Decision';
import { WispContext} from '../WispProvider';
import { doSignin, loadUserSites} from '../util/user.js';
import { completeInvite, createSite } from '../util/site.js';
import { WispNotification } from '../components/WispNotification';
import { WispRadio } from '../components/WispRadio.js';
import { fetchGet, getWispUrl, wisp_urls } from '../util/fetch.js';
import { getCookie } from '../util/base.js';
import { wispHistory } from '../components/WispHistory';
import {WispButton} from '../components/WispButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import {WispInput} from '../components/WispInput'

// bcls - background color class
// d - inline-block, etc
// w - width of button
// js - onclick javascript
// m - margins
// mes - button message

export default function SignInPopup(props) {

    var initiatedSiteLoad = false;
    var wispData = useContext(WispContext);

    const [userId, setUserId] = React.useState(null);
    const [signingIn, setSigningIn] = React.useState(false);
    const [signInState, setSignInState] = React.useState({
        showForgotPassword: false,
    })
    const [alertMessage, setAlertMessage] = React.useState({text: '', type: 'warning'});

    var initEmail = window.localStorage.getItem("email");   //getCookie('email');
    if (process.env.REACT_APP_SUBDOMAINS === 'true') {
        initEmail = getCookie("email");
    }

    useEffect(() => {
        $("#password").attr('type','password');
/*        var email = window.localStorage.getItem("email");   //getCookie('email');
        if (process.env.REACT_APP_SUBDOMAINS === 'true') {
            email = getCookie("email");
        }

        console.log('email = ', email); */
        if (initEmail && typeof initEmail != 'undefined' && initEmail != "") {
            // setInitEmail(email)
            var passwordEl = document.getElementById('password');
            passwordEl.focus();
        } else {
            var emailEl = document.getElementById('email');
            emailEl.focus();
        }
        if (userId && !initiatedSiteLoad) {
            initiatedSiteLoad = true;
            console.log('signinPopup: loadUserSites');
            // hmmm, use of state here probably ok versus directly in call back
            var tinviteSitemap;
            wispData.setInviteSitemap(prevValue => {tinviteSitemap = prevValue; return (prevValue)});
            var tToken;
            wispData.setToken(prevValue => {tToken = prevValue; return (prevValue)});
            if (tinviteSitemap) {
                // what the f###k??? Why is this commented out?
                console.log('gonna complete invite: userId = ' + userId);
                completeInvite(wispData, tinviteSitemap, doneFN, userId, tToken);   // this should call doneFN
                // doneFN(userId, tToken);
            } else {
                loadUserSites(wispData, userId, userId, tToken, getSitesResult, true, userId);
            }
        }

    }, [userId]);

    function setShowForgotPassword(val) {
        if (signInState.showForgotPassword !== val) {
            setSignInState((prevState) => {
                return {
                    ...prevState,
                    showForgotPassword: val,
                }
            });
        }
    }

    function doneFN(userId, token) {        
        loadUserSites(wispData, userId, userId, token, getSitesResult, true, userId);
    }

    function signIn(fromId){
        // Validate inputs
        if ($("#email").val() === '') {
            setAlertMessage({text: 'A valid email is required', type: 'error'});
            return;
        } else if ($("#password").val() === '') {
            setAlertMessage({text: 'Please provide a password', type: 'error'});
            return;
        }

        // passcode or login?
        setAlertMessage({text: '', type: 'warning'});

        var pc = $("#passcode").val();
        if(typeof pc != 'undefined' && pc !== ''){
            window.location = "wt_main_3_9.jsp?id=" + fromId + "&pc=" + pc;
        }
        else{
            var valid = true;
    /*        if(ts_create){
                ts_newacct_teamURL = $("#teamurl").val().toLowerCase();
                ts_newacct_teamName = $("#teamname").val();
                userchar = ts_newacct_teamURL.substring(0,1);
                valid = validateSiteUrl();
            }*/
            if(valid){
                window.ts_h_email = $("#email").val();
                setSigningIn(true);
                doSignin(ts_h_email,$("#password").val(),'',fromId,'', wispData, handleError, onSuccess);
            }
        }
	}

	function onSuccess(userId, token) {
        // make sure sites are loaded - cause BaseLayout won't do it if not signed in when that component loads
        // don't do this until the freaking stupid wispData.setWispUser has completed - you can call out to the backend
        // and return and the stupid A thing may still not have completed (AH HA! - now we know that's a problem with callbacks - need to get fresh data)

        // see useEffect above that watches for userId and then loads user sites
        // loadUserSites(wispData, userId, userId, token, getSitesResult, true);
        setUserId(userId);	
    }

	function getSitesResult(success, data, userId) {
		if (success) {
			wispData.setUserSitesAuth(data);
            wispData.setUserSitesLoaded(true);
            if (!wispData.publicState.showSubscribe && props.onSignInFN) {
                props.onSignInFN(userId, data);
            }
        }
        wispData.setShowSignin(false);
        // if subscribe popup is showing, then just close this window
    }

    function handleError(error) {
        setSigningIn(false);
        setAlertMessage({text: String(error), type: 'error'});
    }
    
    function forgotPassword(){
        setShowForgotPassword(true);
    }

    function decisionCancel(){
        setShowForgotPassword(false);
    }

    function resetPassword(email){
        console.log('resetPassword: email = ' + email);
        decisionCancel();

        var url = process.env.REACT_APP_WISP_URL + "/user/reset_password";
        fetch(url, {method: 'POST', mode: 'cors', headers: {'email': email, 'app-id': process.env.REACT_APP_ID}})
        .then(function(response) {
            console.log('response.status = ' + response.status); // Will show you the status
            return response.json();
        })
        .then(data => {
            showPubMessage2("Your password has been reset. Please check your email.",'20px');
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    function createAcct() {
        wispData.setShowCreateAccount(true);
        wispData.setShowSignin(false);
    }

    function closeSignin() {
        wispData.setShowSignin(false);
    }

    function emailInfo() {

    }

    function handlePasswordKeyUp(e) {
        // console.log('handlePasswordKeyUp = ' + e.target.value);
        if (e.keyCode === 13) {
            signIn();
        }
    }

    function selItem(item) {
        if (item.name.indexOf('new') > 0) {
            createAcct();
        }
    }

    let forgotPasswordDialog;
    if (signInState.showForgotPassword) {
        var email = $("#email").val();
        email = email.toLowerCase();
        var mes = 'Click "Reset Password" and a new password link will be sent to ' + email;
        forgotPasswordDialog = (
            <Decision
                mes={mes}
                c1="Reset Password"
                c2="Cancel"
                fn1={resetPassword}
                fn2={decisionCancel}
                p1={email}
                p2=""
                y="40"
				closeFN={decisionCancel}
                wispData={wispData}
            />
        );
    }

    // var w = $("body").width();
    // var leftPos = (w - 320)/2 + 'px';
    // var cls = 'wisp_but webt_ui_color_1 ' + props.bcls;
    // var w = props.w + "px";
    var catItems = [
        {name: 'I have an existing account',
        },
        {name: 'I need a new account',
        }
    ];

    var createOption = (<div style={{height: '10px'}} />);
    if (props.createOption) {
        createOption = (
            <div className='primaryColor' style={{fontSize:"14px",cursor:"pointer", marginTop: '5px'}} onClick={createAcct}>
                (Don't have an account?)
            </div>
        );
    }

    let signInTitle = (
        <div>
            {process.env.REACT_APP_NAME} Sign In&nbsp;
            {createOption}
        </div>            
    ); 

    var messageDiv;
    var message = wispData.publicState.signinMessage;
    if (message != null) {
        messageDiv = (
            <div className='signin_mes'>{message}</div>
        )
    }

    var alertDiv;
    if (alertMessage.text !== '') {
        alertDiv = (
            <div style={{marginTop: '15px'}}>
                <WispNotification message={alertMessage.text} type={alertMessage.type} />
            </div>
        );
    }

    var noteDiv;
    if (props.note) {
        noteDiv = (
            <div style={{margin: '15px 0px'}}>
                <WispNotification message={props.note} type='warning' />
            </div>
        );
    }
    var logo;
    if (props.logo) {
        logo = (
            <div className="input_logo">
                <img src={props.logo} className="logoClassSmall" style={{margin: 'auto', display: 'block'}}/>
            </div>
        )
    }

    return (
        // <div className={cls}
        //     style={{
        //         display: props.d,
        //         padding:"8px",
        //         fontSize:"16px",
        //         width: w,
        //         textAlign: "center",
        //         cursor: "pointer",
        //         margin: props.m,
        //     }}
        //     onClick={props.js}>
        //     {props.mes}
        // </div>

        <React.Fragment>
            <div id='signin_div'>
                <div className="close_pos_float_signin" onClick={closeSignin}>
                    <div id="fullscreen_close" className="close">
                        <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes}/>
                    </div>
                </div>
                {logo}
                <div style={{textAlign: 'center', fontSize: 24, fontWeight: 'bold'}}>Sign In</div>
                {messageDiv}
                {noteDiv}             
                {/* <h3 id='signin_title'>{signInTitle}</h3> */}
                <div style={{position:'relative'}}>
                    <WispInput
                        id='email'
                        hint='Email Address'
                        initValue={initEmail}
                        inputStyle={{paddingTop: '7px'}}
                        classes='active'
                        ti='1'
                    />
                    <div id='email_div_info' onClick={emailInfo} className='team_c_i'><i className='fa fa-info-circle'></i></div>
                    <div className='wt_floater' style={{display:'none',margin:'20px 10px 30px 10px'}} id='email_desc'><p>Your email address is used to log into {process.env.REACT_APP_NAME}.</p></div>
                    </div>
                    <WispInput
                        id='password'
                        hint='Password'
                        initValue=''
                        inputStyle={{paddingTop: '7px'}}
                        classes='active'
                        ti='1'
                    />
                    <div style={{position:'relative'}}>
                    <div id='referral_div' className="wt_inputdiv active" style={{display: 'none'}}>
                        <div className="input_hint">Referral Code (optional)</div>
                        <div className="input_active_hint">Referral Code (optional)</div>
                        <input tabIndex='2' style={{width:'100%',boxSizing: 'border-box'}} className='wt_input' id='referral' autoComplete='off'/>
                    </div>
                </div>
                <div style={{position:'relative', marginTop: '30px'}}>
                    <WispButton
                        bcls='primaryButton'
                        d='block'
                        js={signIn}
                        m='0px'
                        busy={signingIn}
                        mes="Sign In" />
                    {alertDiv}
                </div>
                <div id='forgotpwDiv' style={{margin:'10px 0px 0px 10px'}} onClick={forgotPassword}>Forgot password?</div>
                <div id='forgotpwDiv' style={{margin:'10px 0px 0px 10px', width: 300}} onClick={createAcct}>Don't have an account?</div>
                {forgotPasswordDialog}
            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );
}

//     return "<div class='wisp_but webt_ui_color_1 " + bcls + "' style='display:" + d + ";padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ";' onclick='javascript:" + js + "'>" + mes + "</div>";
